import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import LanguageContext from '../../context/Language';

function NewsArticleMeta({ type, date }) {
  const context = useContext(LanguageContext);
  const data = useStaticQuery(graphql`
    query NewsArticleMetaQuery {
      en: contentfulLatestNews(
        contentful_id: { eq: "79x6XYS8m8XnuH6fQqBJCg" }
        node_locale: { eq: "en-GB" }
      ) {
        generalNewsCategoryLabel
        communityNewsCategoryLabel
        pressReleaseCategoryLabel
      }
      nl: contentfulLatestNews(
        contentful_id: { eq: "79x6XYS8m8XnuH6fQqBJCg" }
        node_locale: { eq: "nl-NL" }
      ) {
        generalNewsCategoryLabel
        communityNewsCategoryLabel
        pressReleaseCategoryLabel
      }
    }
  `);

  const localData = data[context.lang];
  const localType = () => {
    switch (type[0]) {
      case 'press release':
        return localData.pressReleaseCategoryLabel;
      case 'general news':
        return localData.generalNewsCategoryLabel;
      case 'community news':
        return localData.communityNewsCategoryLabel;
      default:
        return '';
    }
  };
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const formattedDate = new Date(date).toLocaleDateString(
    `${context.lang}-GB`,
    options
  );

  return (
    <>
      <span style={{ textTransform: 'uppercase' }}>{localType()}</span>
      <br />
      {formattedDate}
    </>
  );
}

export default NewsArticleMeta;
