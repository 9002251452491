import React from 'react';

const AssetReferences = ({ references }) => {
  if (references.length === 1) {
    return (
      <p>
        <a
          href={references[0].localFile?.publicURL}
          rel="noreferrer"
          target="_blank"
          data-testid="asset-reference"
        >
          {references[0].title}
        </a>
      </p>
    );
  }

  return (
    <ul>
      {references.map((reference) => (
        <li key={reference.id}>
          <a
            href={reference.localFile?.publicURL}
            rel="noreferrer"
            target="_blank"
            data-testid="asset-reference"
          >
            {reference.title}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default AssetReferences;
