import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { ChevronLeft } from 'react-feather';
import LanguageContext from '../../context/Language';

function BackToLatest() {
  const context = useContext(LanguageContext);
  const data = useStaticQuery(graphql`
    query BackToLatestQuery {
      en: contentfulMenuItem(
        contentful_id: { eq: "WdgiUgxb8c0PDe1lHcMVu" }
        node_locale: { eq: "en-GB" }
      ) {
        id
        title
        link
      }
      nl: contentfulMenuItem(
        contentful_id: { eq: "WdgiUgxb8c0PDe1lHcMVu" }
        node_locale: { eq: "nl-NL" }
      ) {
        id
        title
        link
      }
    }
  `);

  const localData = data[context.lang];

  return (
    <>
      <ChevronLeft size="16" />{' '}
      <Link
        to={localData.link}
        className="link-primary"
        data-testid="back-to-latest"
      >
        {localData.title}
      </Link>
    </>
  );
}

export default BackToLatest;
