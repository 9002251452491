import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import BaseLayout from '../layouts/BaseLayout';
import renderRichTextContent from '../utils/renderRichText';
import HeaderImage from '../components/HeaderImage/HeaderImage';
import LanguageContext from '../context/Language';
import BackToLatest from '../components/BackToLatest/BackToLatest';
import NewsArticleMeta from '../components/NewsArticleMeta/NewsArticleMeta';
import AssetReferences from '../components/AssetReferences/AssetReferences';

const ArticlePage = ({ data }) => {
  const context = useContext(LanguageContext);
  const localData = data[context.lang];

  return (
    <BaseLayout title={localData.title} description={localData.seoDescription}>
      {localData.headerImage && <HeaderImage data={localData} />}
      <section className="section-padding-xs">
        <Container>
          <Row>
            <Col xs="4" md={{ size: 4 }}>
              <BackToLatest />
            </Col>
            <Col
              xs="8"
              md={{ size: 4, offset: 4 }}
              style={{ textAlign: 'right' }}
            >
              <NewsArticleMeta type={localData.type} date={localData.date} />
            </Col>
          </Row>
          <Row style={{ paddingTop: '2rem' }}>
            <Col lg="7">
              <h1>{localData.title}</h1>
              {renderRichTextContent(localData.body)}
              {localData.body.references !== null && (
                <AssetReferences references={localData.body.references} />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </BaseLayout>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticleQuery($slug: String!) {
    en: contentfulNewsArticle(
      slug: { eq: $slug }
      node_locale: { eq: "en-GB" }
    ) {
      seoDescription
      title
      date
      type
      body {
        raw
        references {
          ... on ContentfulAsset {
            id
            contentful_id
            file {
              contentType
            }
          }
          title
          localFile {
            publicURL
          }
        }
      }
      headerImage {
        ...HeaderImageFragment
      }
    }
    nl: contentfulNewsArticle(
      slug: { eq: $slug }
      node_locale: { eq: "nl-NL" }
    ) {
      seoDescription
      title
      date
      type
      body {
        raw
        references {
          ... on ContentfulAsset {
            id
            contentful_id
            file {
              contentType
            }
          }
          title
          localFile {
            publicURL
          }
        }
      }
      headerImage {
        ...HeaderImageFragment
      }
    }
  }
`;
